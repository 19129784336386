<template>
  <h1>Single project</h1>
</template>

<script>
export default {
  name: "ProjectIdPage",
};
</script>

<style></style>
